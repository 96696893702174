import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO />
    <h1>Page not found</h1>
  </Layout>
);

export default NotFoundPage;
